(function ($) {
    $(document).ready(function () {
        
        // Scroll top
        $('.js-top').on('click', function(e){
            e.preventDefault();
            
            $('html, body').animate({
                scrollTop:0
            }, 'slow');
        });
        
        // Push menu
        $('#mp-menu').pushMenu({
            type: 'overlap',
            levelSpacing: 0,
            backClass: 'mp-back',
            trigger: '#trigger',
            pusher: '.site-outer',
            afterOpen: function(menu, level) {
                window.scroll(0, 0);
            }
        });

        // Smooth scroll to ID
        $('.js-scroll-to').on('click', function(e){
            e.preventDefault();
            target = '';
            if(this.tagName == 'DIV' || this.tagName == 'FIGURE') {
                // A hacky fix here, but no option to fix the PHP code.
                // The server side code was adding a '/' to the #ID, this
                // code removes that.
                target = $(this).find('a').attr('href');
                if (target.charAt(0) == '/' && target.indexOf('#')) {
                    target = $(target.substring(1));
                }
            } else {
                target = $($(this).attr('href'));
            }
            if(target.length)   {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 'slow');
            }
        });
    });
})(jQuery);